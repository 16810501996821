<template>
  <div class="single blog page">
    <section id="banner" class="align-middle">
      <banner-image-overlay></banner-image-overlay>
      <h2 class="title">Can I learn Spanish with Duolingo?</h2>
    </section>
    <div class="container 75%">
      <section class="myspanishnow-story row 200%">
        <div>
          <div class="row">
            <div class="col-lg-6">
              <p>Hola {{ getCurrentSeasonHeart() }}</p>
              <p>The answer is yes, you can learn with Duolingo, as well as by watching YouTube videos, movies or listening to music.</p>
              <p>But watch out! I would say that it is <b>impossible to improve your level of Spanish only with this tool</b>. Unless you're really good with languages, of course.</p>
              <p>For me, the main purpose of a language is: Put it into practice. Give it a use!</p>
            </div>
            <div class="col-lg-6">
              <img
                src="/images/blog/learn-spanish-with-duolingo/main.jpg"
                alt="Starting the duolingo app in a smartphone"
                loading="lazy"/>
            </div>
          </div>
          <blockquote class="blockquote text-center">
            <p>What is the point of keep learning more and more vocabulary, when in your vacation you are unable to ask for the menu?</p>
          </blockquote>
          <p>In my modest opinion, getting to use Spanish only with Duolingo is an arduous task.</p>
          <p>Keep perfecting your Spanish with Duolingo but don't see it as the only tool. Duolingo is a good complement, but if you are not in a budget, why not find a teacher that guides you, advise you and  focus on what you need and explain why you need it? I'm sure you won't regret it.</p>
          <p>Nevertheless, bellow there are some ideas to learn/practice your Spanish for free:</p>
          <ol>
            <li>Watch series and/or movies in Spanish</li>
            <li>Listen to music in Spanish</li>
            <li>Travel to a Spanish speaking country</li>
            <li>Read in Spanish: books, magazines and newspapers</li>
            <li>Talk to natives: order food in Spanish in a nearby Spanish restaurant</li>
            <li>Change your default laptop and phone language to Spanish</li>
          </ol>
          <p>Here are some free activities and infographics that can help you continue learning:</p>
          <ul>
            <li><a href="/files/blog/learn-spanish-with-duolingo/ser-y-estar.pdf" target="_blank">Exercises with the verbs <i>ser y estar</i></a></li>
            <li><a href="/files/blog/learn-spanish-with-duolingo/preguntas-del-primer-día.pdf" target="_blank">Match speaking questions</a></li>
          </ul>
          <p>See you soon until then, APRENDE 📱🇪🇸 {{ getCurrentSeasonEmoji() }}</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import BannerImageOverlay from '../../../components/common/utils/BannerImageOverlay.vue';

export default {
  name: 'LearnSpanishWithDuolingo',
  components: {
    BannerImageOverlay,
  },
};
</script>

<style scoped>
  img {
    border-radius: 25px;
    width: 100%;
    margin-bottom: 2em;
  }

  .blog.single > .container {
    margin-top: 50px;
  }

  blockquote {
    margin-bottom: 2em;
  }

  blockquote p {
    margin: 0;
  }

  ul {
    list-style: none;
  }
</style>
